
//=============================================================================
// Pantalla Notas del proceso
//=============================================================================

import './css/App.css';
import React, { useState, useEffect, useRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import APICALL from './APICALL';
import AICALL from './AICALL';

export default function Editor() {

  const navigate = useNavigate();
  const [text, setText]                   = useState('');
  const [fetchedData, setFetchedData]     = useState(''); 
  const [memos, setmemos]                 = useState([]); 
  const [inputKey, setInputKey]           = useState(Date.now());
  const [hiddenButtons, setHiddenButtons] = useState([]);
  const fileInputRef                      = useRef(null);
  const processname                       = sessionStorage.getItem("process");

  async function SaveData() {
    if(text !== ''){
      try {
        await APICALL({
          apicall: 'PD_update',
          code: processname, 
          U_emp: sessionStorage.getItem('Grupo'),

          notas: text
        });
      } catch (error) {
        console.error('Error during fetch:', error);
        return { token: null };
      }
    }
  };

  async function DropContent() {
    setInputKey(Date.now());
    localStorage['archivo'] = ''
  };
  
  async function handleFileChange(event) {
    const file = event.target.files[0];
  
    if (!file) {
      return;
    }
  
    const fileMimeType = file.type; 
    console.log('File received:', file);
  
    if (fileMimeType === 'application/pdf') {
      const data = new FormData();
      data.append('file', file);
      data.append('apicall', 'EX');
  
      try {
        const reply = await AICALL(data);
        localStorage['archivo'] = reply;
        // setText(reply.reply)
      } catch (error) {
        console.error('Error during PDF processing:', error);
      }
      
  
    } else if (fileMimeType === 'text/plain') {
      const reader = new FileReader();
      reader.onload = function(e) {
        const content = e.target.result;
        document.getElementById('fileContent').textContent = content;
        localStorage['archivo'] = content;
        setText(content)
      };
      reader.readAsText(file);
      
  
    } else {
      DropContent()
      window.alert('Formato no soportado. Actualmente solo se aceptan los formatos:\n * .PDF\n * .TXT');
    }
  }
  

  const handleChange = (event) => { 
    setText(event.target.value); 
  };

  const handleButtonClick = (item) => {
    setHiddenButtons([...hiddenButtons, item]);
  };

  const goto01 = () => {
    SaveData();
    navigate('/MisProcesos');
  };

  const goto04 = () => {
    localStorage["notas"]   = text;
    SaveData();
    navigate('/EProceso');
  };

  useEffect(() => {
    localStorage['reply'] = ''
    localStorage['notas'] = ''
    const fetchData = async () => {
      setmemos(require("./docs/Preguntas.json"));
      const data = await APICALL({
        apicall :'PD_fetch' ,
        code :sessionStorage.getItem("process"),
        U_emp: sessionStorage.getItem('Grupo')
      });
      setFetchedData(data);
      setText(data.notas);
      localStorage['archivo'] = ''
      localStorage['notas'] = data.notas
    };
    fetchData();
  }, []);

  const dataItems = [
    { label: 'EMPRESA',                 value: fetchedData.E_name 			}, 
    { label: 'CARGO',                   value: fetchedData.Charge_name 	}, 
    { label: 'INTERLOCUTOR',            value: fetchedData.I_name 			},
		{ label: 'PARTNER ENCARGADO',       value: fetchedData.R_name 		  }
  ];
  
  //############################################################################################################
  //############################################################################################################

  return (
    // main 
    <div className="BackGround">
      <div className="typing-effect-container">
        <h2 className="typing-effect" >
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          👇 Levantaremos perfil con tu cliente (interno o externo), registra aquí las notas que tomes de esa conversación 👇
        </h2>
			</div>
      
      <div className="MainBody">
        <div className='boxfit'>
          <h3 className = 'boxtitle'>Tus notas</h3>
          <textarea className='notas'
            value={text}
            onChange={handleChange}
            placeholder="escriba aqui sus notas..."
            spellCheck 
          />
        </div>
        &nbsp; 

        <div style={{display:'flex', flexDirection: 'column'}}>
          <div className="data-boxes">
            {dataItems.map((item, index) => (
              <div  key={index} 
                    className="data-box">
          
                <strong>{item.label}:</strong> {item.value}
              </div>
            ))}
            <div style={{display: 'flex' , gap : '0.5vh'}}>
              <input  key = {inputKey}
                      id = "fileContent"
                      type="file"  
                      onChange={handleFileChange}
                      ref={fileInputRef} 
                      className="data-box"/>
              
              <button onClick={DropContent}>
                      Eliminar Archivo</button>
            </div>
          </div>
          &nbsp; 
          <div className='memos-box'>
            <div className='memos-title'>
              <h3>
                {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                Acá te dejo algunas recomendaciones para el levantamiento de perfil 👇
              </h3>
            </div>
              {memos.map((item) => (
                <button
                  key={item}
                  className={`memos ${hiddenButtons.includes(item) ? 'hidden' : ''}`}
                  onClick={() => handleButtonClick(item)}
                >
                  <strong>{item} </strong> 
                </button>
              ))}
          </div>
        </div>
      </div>
        <div className='bottom'>
          <button onClick={goto01}>volver</button>
          <button className='last-button' onClick={goto04}>Guardar y Continuar</button>
        </div>
    </div>
  );
}
