
//=============================================================================
// Pantalla Generación Oferta/ Preguntas | Publicación
//=============================================================================

import './css/App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { jsPDF } from 'jspdf';
import APICALL from './APICALL';
import AICALL from './AICALL';

export default function Public() {

  const navigate = useNavigate();
  const [oferta, setOferta] = useState('');
  const handleChange = (event) => { setOferta(event.target.value) };
  const [fetchedData, setFetchedData] = useState(''); 
  const [Claves, setClaves] = useState(['']);
  const [pregunta1, setPregunta1] = useState('');
  const [pregunta2, setPregunta2] = useState('');
  const [pregunta3, setPregunta3] = useState('');
  const [pregunta4, setPregunta4] = useState('');
  const [Final1, setFinal1] = useState('');
  const [Final2, setFinal2] = useState('');
  const [Final3, setFinal3] = useState('');
  const [Final4, setFinal4] = useState('');
  const [wait, setwait] = useState(false);
  const [Publicar, setPublicar] = useState(false);
  const [Actual, setActual] = useState(false);
  const [regen, setregen] = useState(false);
  const [IDlist,setIDlist] = useState([]);

  const notas1 = 'Genera En el titulo utiliza el formato : '
                  + 'Buscamos {nombre del cargo} para compañía de {industria donde se busca al candidato}.'
                  + 'Genera El aviso como un proceso de búsqueda para la empresa' 
                  + sessionStorage.getItem('grupo')
                  + 'En el texto generado refiere a la empresa como "nuestro cliente" en lugar de su nombre';

  const notas2 = 'Genera El aviso como un proceso de busqueda para una empresa externa' 
                  + 'Mantieniendop un lenguaje formal del estilo "la empresa"';

  const notas3 = 'Genera El aviso como un proceso de busqueda para una empresa incognita' 
                  + 'evita cualquier mencion del nombre de la empresa';

  async function GenerateOferta(notas) {
    setwait(true);
    setOferta('Renee esta trabajando en su respuesta ...')
    try {
      const newText = await AICALL({
        apicall: '02',
        data: localStorage.getItem('reply') 
              + localStorage.getItem('fetched')
              + notas
      });
      setOferta(formatResponse(JSON.parse(newText.reply)));
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
    setTimeout(() => {
      setwait(false);
    }, 1000);
  };

  async function exportToTrabajando() {
    await SaveData()
    setwait(true);

    try {
      const reply = await APICALL({
        apicall: 'TB_create',
        code: sessionStorage.getItem('process'),
        user: sessionStorage.getItem('token'),
        mail: sessionStorage.getItem('mail'),
        U_emp: sessionStorage.getItem('Grupo')
      });
      SaveJobId(reply.id);
      window.open(reply.url);
    } catch (error) {
      console.error('Error during fetch:', error);
    }
    setTimeout(() => {
      setwait(false);
    }, 1000);
  };

  async function UpdateTrabajando() {
    await SaveData()
    setwait(true);

    try {
      const reply = await APICALL({
        apicall: 'TB_update',
        code: sessionStorage.getItem('process'),
        user: sessionStorage.getItem('token'),
        mail: sessionStorage.getItem('mail'),
        U_emp: sessionStorage.getItem('Grupo')
      });
      SaveJobId(reply.id);
      window.open(reply.url);
    } catch (error) {
      console.error('Error during fetch:', error);
    }
    setTimeout(() => {
      setwait(false);
    }, 1000);
  };

  async function GeneratePreguntas() {
    setwait(true);
    if (!Final1){
      setPregunta1('Renee esta trabajando en su respuesta ...')
    }
    if (!Final2){
      setPregunta2('Renee esta trabajando en su respuesta ...')
    }
    if (!Final3){
      setPregunta3('Renee esta trabajando en su respuesta ...')
    }
    if (!Final4){
      setPregunta4('Renee esta trabajando en su respuesta ...')
    }
    
    try {
      const newText = await AICALL({
        apicall: '03',
        data: localStorage.getItem('reply') + localStorage.getItem('fetched')
      });
    
      const parsedReply = JSON.parse(newText.reply);
    
      if (!Final1) {
        setPregunta1(parsedReply.Pregunta_1);
      }
      if (!Final2) {
        setPregunta2(parsedReply.Pregunta_2);
      }
      if (!Final3) {
        setPregunta3(parsedReply.Pregunta_3);
      }
      if (!Final4) {
        setPregunta4(parsedReply.Pregunta_4);
      }
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
    
    setTimeout(() => {
      setwait(false);
    }, 1000);
  };

  async function SaveData() {
    setwait(true);
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'), 
        Claves: Claves,
        GPToferta: oferta,
        GPTpreguntas: [pregunta1.replace(/"/g, '') , pregunta2.replace(/"/g, '') , pregunta3.replace(/"/g, '') , pregunta4.replace(/"/g, '')]
      });
      return true;
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }  finally {
      setTimeout(() => {
        setwait(false);
      }, 1000); 
    }
  };

  async function SaveJobId(ID) {
    if (ID !== null && ID !== '' && !IDlist.includes(ID)) {
      try {
        IDlist.push(ID);
        await APICALL({
          apicall: 'PD_update',
          code: sessionStorage.getItem('process'),
          U_emp: sessionStorage.getItem('Grupo'),
          JobId: IDlist
        });
        return true;
      } catch (error) {
        console.error('Error during fetch:', error);
        return { token: null };
      }
    }
  };

  async function fetchData () {
    const data = await APICALL({
      apicall :'PD_fetch' ,
      code :sessionStorage.getItem("process"),
      U_emp: sessionStorage.getItem('Grupo')
    });
    localStorage.removeItem('fetched');
    localStorage.setItem('fetched', data);
    setOferta(data.GPToferta);
    setPregunta1(data.GPTpreguntas);
    if (!data.GPToferta){
      GenerateOferta();
    } 
    if (!data.GPTpreguntas){
      GeneratePreguntas();
    }
  };

  // const exportToPDFoferta = () => {
  //   const doc = new jsPDF();

  //   doc.text(oferta, 10, 10);
  //   doc.save('oferta.pdf');
  // };

  const formatResponse = (jobDetails) => {
    
    let formattedText = "";

    formattedText += jobDetails.Descripcion_de_la_empresa+ '\n\n';
  
    formattedText += `**Misión del Cargo:**\n${jobDetails.Mision_del_cargo}\n\n`;
  
    formattedText += `**Responsabilidades:**\n`;
    jobDetails.Responsabilidades.forEach((responsabilidad, index) => {
      formattedText += `~${responsabilidad}~\n`;
    });
    formattedText += `\n`;
  
    formattedText += `**Requisitos Clave:**\n`;
    jobDetails.Claves.forEach((clave, index) => {
      formattedText += `~${clave}~\n`;
    });
    formattedText += `\n`;
  
    formattedText += `**Oferta:**\n`;
    formattedText += `~Banda Salarial: ${jobDetails.Oferta.banda_salarial}~\n`;
    formattedText += `~Horario: ${jobDetails.Oferta.horario}~\n`;
    formattedText += `~Modalidad de Trabajo: ${jobDetails.Oferta.modalidad_de_trabajo}~\n`;
    formattedText += `~Beneficios Adicionales: ${jobDetails.Oferta.beneficios_adicionales}~\n`;
    formattedText += `\n`;

    formattedText += jobDetails.Captura + '\n';

    let formatedclave = ''
    jobDetails.Claves.forEach((clave, index) => {
      formatedclave += `~${clave}~\n`;
    });
    setClaves(formatedclave);
    return formattedText;
  }

  const goto05 = () => { navigate('/GenPerfil') };
  const goto07 = () => { navigate('/Candidatos') };

  useEffect(() => {
    const fetchData = async () => {
      const data = await APICALL({
        apicall :'PD_fetch' ,
        code :sessionStorage.getItem("process"),
        U_emp: sessionStorage.getItem('Grupo')
      });
      localStorage.removeItem('fetched');
      localStorage.setItem('fetched', data);
      setIDlist(data.JobId || []);
      setFetchedData(data);
      setClaves(data.Claves)
      if (data.GPToferta){
        setOferta(data.GPToferta);
      } else {
        GenerateOferta();
      }
      if (data.GPTpreguntas){
        
        setPregunta1(data.GPTpreguntas[0]);
        setPregunta2(data.GPTpreguntas[1]);
        setPregunta3(data.GPTpreguntas[2]);
        setPregunta4(data.GPTpreguntas[3]);
      } else {
        GeneratePreguntas();
      }
      
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const dataItems = [
    { label: 'CARGO',                   value: fetchedData.Charge_name 	? fetchedData.Charge_name.toUpperCase() : '' }, 
    { label: 'EMPRESA',                 value: fetchedData.E_name 			? fetchedData.E_name.toUpperCase() : '' }, 
    { label: 'INTERLOCUTOR',            value: fetchedData.I_name 			? fetchedData.I_name.toUpperCase() : '' },
		{ label: 'PARTNER ENCARGADO',       value: fetchedData.R_name 		  ? fetchedData.R_name.toUpperCase() : '' }
  ];

  //############################################################################################################
  //############################################################################################################

  return (
    // main
    <div className="BackGround">
      <div className="typing-effect-container">
				<h2 className="typing-effect" > 
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          😍 También he creado para ti este AVISO DE EMPLEO. Puedes editarlo o pedirme que lo genere nuevamente
        </h2>
			</div>
      
      <div className='MainBody'>

        <div className='boxfit'>
          <h3 className = 'boxtitle'>Aviso Generado</h3>
          <textarea className='notas'
            value={oferta}
            onChange={handleChange}
            spellCheck 
          />
        </div>
        &nbsp;
        <div className='boxfit'>
          <h3 className = 'boxtitle'>Preguntas de Aviso</h3>
          <div className='notas'>
            <textarea className={Final1 ? 'pSaved' : 'preguntas'}
              value={pregunta1.replace(/"/g, '')}
              placeholder="espere mientras se genera el contenido..."
              onChange={(event) => { let newValue = event.target.value ; setPregunta1(newValue) }}/>
            <button style={{marginBottom:'0.5vh'}} onClick={() => setFinal1(pregunta1)}> Seleccionar Pregunta </button>

            <textarea className={Final2 ? 'pSaved' : 'preguntas'}
              value={pregunta2.replace(/"/g, '')}
              placeholder="espere mientras se genera el contenido..."
              onChange={(event) => { let newValue = event.target.value ; setPregunta2(newValue) }}/>
            <button style={{marginBottom:'0.5vh'}} onClick={() => setFinal2(pregunta2)}> Seleccionar Pregunta </button>

            <textarea className={Final3 ? 'pSaved' : 'preguntas'}
              value={pregunta3.replace(/"/g, '')}
              placeholder="espere mientras se genera el contenido..."
              onChange={(event) => { let newValue = event.target.value ; setPregunta3(newValue) }}/>
            <button style={{marginBottom:'0.5vh'}} onClick={() => setFinal3(pregunta3)}> Seleccionar Pregunta </button>

            <textarea className={Final4 ? 'pSaved' : 'preguntas'}
              value={pregunta4.replace(/"/g, '')}
              placeholder="espere mientras se genera el contenido..."
              onChange={(event) => { let newValue = event.target.value ; setPregunta4(newValue) }}/>
          <button onClick={() => setFinal4(pregunta4)}> Seleccionar Pregunta </button>

          </div>
        </div>
        &nbsp; 
        <div className="data-boxes">
          {dataItems.map((item, index) => (
            <div key={index} className="data-box">
              <strong>{item.label}:</strong> {item.value}
            </div>
          ))}
          <div style={{display:'grid', gridTemplateColumns:'1fr 1fr', columnGap:'0.5vh', rowGap:'0.5vh'}}>

            <button onClick={GeneratePreguntas}>Regenerar Preguntas Sugeridas</button>
            <button onClick={() => {setregen(!regen); setPublicar(false)}}> Regenerar Aviso Laboral </button>
            
            <button className = {regen ? "PublicButon" : "hidden"}
                    onClick={() => GenerateOferta('')}> Regenerar nuevo Aviso Laboral</button>
            <button className = {regen ? "PublicButon" : "hidden"}
                    onClick={() => GenerateOferta(notas1)}>Regenerar como Consultora</button>
            <button className = {regen ? "PublicButon" : "hidden"}
                    onClick={() => GenerateOferta(notas2)}>Regenerar como Empresa Contratante</button>
            <button className = {regen ? "PublicButon" : "hidden"}
                    onClick={() => GenerateOferta(notas3)}>Regenerar como Empresa Confidencial</button>

            <button onClick={SaveData}> Guardar Aviso y preguntas </button>
            <button onClick={fetchData}>Volver a la ultima version guardada</button>

            <button className= {IDlist.length > 3 ? 'hidden' : ''}
                    style={{border:'0.6vh solid var(--main)'}}
                    onClick={() => {setPublicar(!Publicar); setActual(false); setregen(false)}}>Publicar el Anuncio</button>

            <button className= {IDlist.length > 0 ? '' : 'hidden'}
                    style={{border:'0.6vh solid var(--main)'}}
                    onClick={() => {setActual(!Actual); setPublicar(false); setregen(false)}}>Actualizar el Anuncio</button>

            {/* <button onClick={exportToPDFoferta}>Exportar Aviso de Empleo a PDF</button> */}

              <button className = {Publicar ? "PublicButon" : "hidden"}
                      onClick={exportToTrabajando}>Publicar en Trabajando.com</button>
              <button className = {Publicar ? "PublicButoff" : "hidden"}
                      >Publicar en LinkedIn</button>
              <button className = {Publicar ? "PublicButoff" : "hidden"}
                      >Publicar en Laborum</button>
              <button className = {Publicar ? "PublicButoff" : "hidden"}
                      >Publicar en Chile Trabajo</button>

              <button className = {Actual ? "PublicButon" : "hidden"}
                      onClick={UpdateTrabajando}>Actualizar en Trabajando.com</button>
              <button className = {Actual ? "PublicButoff" : "hidden"}
                      >Publicar en LinkedIn</button>
              <button className = {Actual ? "PublicButoff" : "hidden"}
                      >Publicar en Laborum</button>
              <button className = {Actual ? "PublicButoff" : "hidden"}
                      >Publicar en Chile Trabajo</button>
                      
          </div>
          <div>
            {wait === false ? (
              <div>
              </div>
            ) 
            : (
              <div className='box'>
                <strong>Espere Mientras Reneé trabaja</strong>
                <div className="loader-container">
                  <div className="pulsing-dot">
                  <div></div>
                  <div></div>
                  <div></div>
                  </div>
                </div>
              </div>
            )}
          </div>  
        </div>
      </div>
      <div className='bottom'>
        <button onClick={goto05}>volver</button>
        <button className={IDlist.length > 0 ? 'last-button' : 'hidden'} onClick={() => {SaveData(); goto07()}}>Guardar y Continuar</button>
      </div>
    </div>
  );
}
