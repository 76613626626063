
//=============================================================================
// Pantalla Recuperación de Perfiles
//=============================================================================

import './css/App.css';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import APICALL from './APICALL';
import AICALL from './AICALL';
import StarRating from '../components/star';
import jsPDF from 'jspdf';

export default function Recuperar() {

  const navigate = useNavigate();
  const [CV, setCV] = useState(null);
  const [CVlist, setCVlist] = useState([]);
  const [selectlist, setselectlist] = useState([]);
  const [selectQuest, setselectQuest] = useState([]);
  const [selectedCVId, setSelectedCVId] = useState(null);
  const [sortType, setSortType] = useState('score');
  const [screentype, setscreentype] = useState('perfil');
  const [score, setscore] = useState('0');
  const [wait, setwait] = useState(false);
  const [IDlist,setIDlist] = useState([]);

  const processedIds = new Set();
  const fileInputRef = useRef(null);

  async function handleRatingChange ( newRating ) {
    setscore(newRating);
    setCVlist(prevCVlist =>
      prevCVlist.map(cv =>
        cv.name === selectedCVId
          ? { ...cv, stars: newRating} 
          : cv
      )
    );
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        CVData: { name: selectedCVId, stars: newRating}
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }

  };

  async function exportToPDFCV ( ciego ) {
    setwait(true);
    const doc = new jsPDF({
      format: 'a4',
      compress: true,
    });
  
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 8;
  
    const logoRenee = new Image();
    logoRenee.src = "/favicon.png";
    const logoWidth = 25;
    const logoHeight = 25;
  
    const leftColumnWidth = (pageWidth - 3 * margin) * 0.35;
    const rightColumnWidth = (pageWidth - 3 * margin) * 0.65;
    const fontsize = 10;
    const titleFontSize = 12;
    const contentMarginTop = 12;
    let yLeft = contentMarginTop;
    let yRight = contentMarginTop;
  
    const drawLeftColumnBackground = () => {
      doc.setFillColor(222, 237, 254);
      doc.rect(0, 0, leftColumnWidth + 1.5 * margin, pageHeight, 'F');
    };
  
    drawLeftColumnBackground();
  
    var img = new Image();
    img.src = CV.personalInfo.picture ? CV.personalInfo.picture + "?not-from-cache-please" : '/default.png';
  
    doc.addImage(img, 'JPEG', margin, margin, 40, 40);
    yLeft = margin + 40 + 10;
  
    const printText = (text, x, y, isTitle = false, columnWidth, addMargin = true) => {
      const fontStyle = isTitle ? 'bold' : 'normal';
      const fontSize = isTitle ? titleFontSize : fontsize;
  
      doc.setFont('helvetica', fontStyle);
      doc.setFontSize(fontSize);
  
      const lineHeight = fontSize * 0.5;
      const textLines = doc.splitTextToSize(text, columnWidth);
  
      textLines.forEach((subLine) => {
        if (y + lineHeight > pageHeight - margin) {
          doc.addPage();
          drawLeftColumnBackground();
          yLeft = contentMarginTop;
          yRight = contentMarginTop; 
        }
        doc.text(x, y, subLine);
        y += lineHeight;
      });
      if (addMargin) y += 4; 
      return y;
    };
  
    const [day, month, year] = CV.personalInfo.birthDate.split('/').map(Number);
    const birthdate = new Date(year, month - 1, day);
    const today = new Date();
  
    let age = today.getFullYear() - birthdate.getFullYear();
    const monthDiff = today.getMonth() - birthdate.getMonth();
  
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthdate.getDate())) {
      age--;
    }
  
    const leftColumnData = [
      `${CV.personalInfo.firstName} ${CV.personalInfo.lastName} \n ${age} Años`,
      `${CV.presentation}`,
    ];
  
    if (!ciego) {
      leftColumnData.push(
        `Email: \n ${CV.personalInfo.emails.primaryEmail}`,
        `Teléfono: \n ${CV.personalInfo.phoneNumbers[0].number}`,
        `Dirección: \n ${CV.personalInfo.address ? CV.personalInfo.address + ', ' : ''} ${CV.personalInfo.communeName || ''}, Región ${CV.personalInfo.regionName || ''}`,
        "Redes sociales",
        `LinkedIn: \n ${CV.socialNetworks.linkedinLink || ' - - - '}`,
        `Facebook: \n ${CV.socialNetworks.facebookLink || ' - - - '}`
      );
    }
  
    const rightColumnData = [
      "Experiencia Laboral",
        ...CV.workExperience
          .slice() 
          .sort((a, b) => new Date(b.fromDate) - new Date(a.fromDate)) 
          .flatMap(job => [
            `${job.companyName} | ${job.jobPosition}`,
            `${job.fromDate} - ${job.toDate || 'Presente'}`,
            ...job.achievements.split('\n').map(achievement => `• ${achievement}`).filter(line => line.trim() !== ''),
            '',
          ]),
      "Educación",
      ...CV.studies.higherEducation
        .slice() 
        .sort((a, b) => new Date(b.entryYear) - new Date(a.entryYear))
        .flatMap(edu => [
        `${edu.otherCareer}${edu.minor ? ' | ' + edu.minor : ''}`,
        `${edu.institution.name}`,
        `${edu.entryYear} - ${edu.graduationyear || 'Presente'}`,
        '', 
      ]),
    ];
  
    const fillColumns = (leftData, rightData) => {
      let leftIndex = 0;
      let rightIndex = 0;
    
      while (leftIndex < leftData.length || rightIndex < rightData.length) {
        if (yLeft + fontsize * 0.5 > pageHeight - margin && yRight + fontsize * 0.5 > pageHeight - margin) {
          doc.addPage();
          drawLeftColumnBackground();
          yLeft = contentMarginTop;
          yRight = contentMarginTop;
        }
    
        if (leftIndex < leftData.length) {
          yLeft = printText(leftData[leftIndex], margin, yLeft, leftIndex === 0, leftColumnWidth);
          leftIndex++;
        }
    
        if (rightIndex < rightData.length) {
          if (yRight + fontsize * 0.5 > pageHeight - margin) {
            doc.addPage();
            drawLeftColumnBackground();
            yLeft = contentMarginTop;
            yRight = contentMarginTop; 
          }
          yRight = printText(rightData[rightIndex], leftColumnWidth + 2 * margin, yRight, rightIndex === 0, rightColumnWidth);
          rightIndex++;
        }
      }
    };
    
  
    fillColumns(leftColumnData, rightColumnData);
  
    const addFooterAndPageCount = (doc) => {
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        const pageText = `Pagina ${i} de ${pageCount}`;
        const pageHeight = doc.internal.pageSize.getHeight();
        doc.text(margin, pageHeight - margin, pageText, { align: 'left' });
  
        doc.addImage(logoRenee, 'PNG', pageWidth - logoWidth - margin, pageHeight - logoHeight - margin, logoWidth, logoHeight);
      }
    };
  
    addFooterAndPageCount(doc);

    if (!ciego) {
      doc.save(`CV_${CV.personalInfo.firstName}_${CV.personalInfo.lastName}.pdf`);
    } else {
      doc.save(`CV_${CV.personalInfo.firstName}_${CV.personalInfo.lastName}_Ciego.pdf`);
    }

    setwait(false);
  };
  
  async function HandleIDFetch () {
    let input = ''
    if (IDlist && Array.isArray(IDlist)){
      input = prompt(
        'IDs actuales:\n' +
        IDlist.map(id => '> ' + id).join('\n') + 
        '\n Ingrese el ID del proceso:'
      );
    } else if (IDlist) {
      input = prompt(
        'IDs actuales:\n' +
        IDlist + 
        '\n Ingrese el ID del proceso:'
      );
    } else {
      input = prompt(
        '\n Ingrese el ID del proceso:'
      );
    }
    if (input !== null && !IDlist.includes(input)) {
      try {
        IDlist.push(input);
        await APICALL({
          apicall: 'PD_update',
          code: sessionStorage.getItem('process'),
          U_emp: sessionStorage.getItem('Grupo'),
          JobId: IDlist
        });
      } catch (error) {
        console.error('Error during fetch:', error);
        return { token: null };
      }
      setCV(null); 
      handlecandidatos(input); ;
    }
  };
  
  async function handleReneeopinion( CV , name ) {

    const instruction = 'EL Perfil: ' + sessionStorage.getItem('currentprofile') 
                 + '. \n El candidato: ';

    setCVlist(prevCVlist =>
      prevCVlist.map(cv =>
        cv.name === name
          ? { ...cv, Nota: null } 
          : cv
      )
    );

    try {
      const consulta = await AICALL({
        apicall: '05',
        data: instruction + JSON.stringify(CV.personalInfo) + JSON.stringify(CV.workExperience) + JSON.stringify(CV.studies)
      });

      const parsedconsulta = JSON.parse(consulta.reply)
      
      setCVlist(prevCVlist =>
        prevCVlist.map(cv =>
          cv.name === name
            ? { ...cv, Nota: parsedconsulta.Evaluacion } 
            : cv
        )
      );
      return parsedconsulta.Evaluacion
    } catch (error) {
      console.error('Error during fetch:', error);
    }
    
  };

  async function Handleselect( name ) {
    setCVlist(prevCVlist =>
      prevCVlist.map(cv =>
        cv.name === name
          ? { ...cv, select: !cv.select} 
          : cv
      )
    );
  
    const updatedSelectList = selectlist.includes(name)
      ? selectlist.filter(id => id !== name)
      : [...selectlist, name];
  
    setselectlist(updatedSelectList);
    
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        SelectList: updatedSelectList 
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
  };
  
  async function handlecandidatos( data ) {

    setwait(true);

    sessionStorage.setItem('currentsave' , sessionStorage.getItem('process'))
    sessionStorage.setItem('currentprofile' , sessionStorage.getItem('perfil'))

    try {
      const reply = await APICALL({
        apicall: 'TB_list',
        code: sessionStorage.getItem('currentsave'),
        U_emp: sessionStorage.getItem('Grupo'),
        jobID: data
      });


      let list = [];
      if (reply) {
        list = reply.data.sharedPostulations || [];
  
        const cvPromises = list.map(async (applicant) => {
          if (!processedIds.has(applicant.applicantId)) {
            processedIds.add(applicant.applicantId);
            await handleCV(applicant.applicantId, data, applicant.questions);
          } 
        });
  
        await Promise.all(cvPromises);
      }
    } catch (error) {
      // console.error('Error during fetch:', error);
    }

    setwait(false);

  };

  async function lookCV( data ) {

    const apiCallType = data.includes('CVE_') ? 'CV_fetch' : 'TB_fetch';


    try {
      const reply = await APICALL({
        apicall: apiCallType,
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        id_code: data
      });

      function replaceNullStrings(obj) {
        for (const key in obj) {
          if (obj[key] === "Null" || obj[key] === null) {
            obj[key] = ' - - -';
          } else if (typeof obj[key] === "object" && obj[key] !== null) {
            replaceNullStrings(obj[key]);
          }
        }
        console.table(obj)
        return obj;
      }
      
      let profile 

      if (apiCallType === 'CV_fetch'){
        profile = replaceNullStrings(reply.data)
      } else {
        profile = reply.data
      }

      const { personalInfo, socialNetworks, workExperience, presentation, studies, multipleDocuments } = profile;
      const cv = { personalInfo, socialNetworks, workExperience, presentation, studies, multipleDocuments };

      setCV(cv);
  
    } catch (error) {
      console.error('Error during fetch:', error);
    }

  };

  async function handleCV( data , jid , questions ) {
  
      try {
        const reply = await APICALL({
          apicall: 'TB_fetch',
          code: sessionStorage.getItem('currentsave'),
          U_emp: sessionStorage.getItem('Grupo'),
          id_code: data
        });
    
        const { personalInfo, socialNetworks, workExperience, presentation, studies } = reply.data;
        const name = `${personalInfo.firstName} ${personalInfo.lastName}`;
        const mail = `${personalInfo.emails.primaryEmail}`;
        const cv = { personalInfo, socialNetworks, workExperience, presentation, studies };
    
        setCVlist(prevCVlist => {
          const existingCV = prevCVlist.find(cv => cv.id === data);
    
          if (reply && !existingCV) {
            const updatedList = [...prevCVlist, { name: name, id: data, new: true }];
            handleReneeopinion(cv, name).then(nota => {
              saveCV(name, mail, data, jid, nota, questions);
              setCVlist(prevCVlist =>
                prevCVlist.map(cv =>
                  cv.name === name
                    ? { ...cv, questions: questions} 
                    : cv
                )
              );
            });
            
            return updatedList;
          } else {
            return prevCVlist;
          }
        });
    
      } catch (error) {
        console.error('Error during fetch:', error);
      } 
  };

  async function handleCVpropio() {
    const curriculum = CV.multipleDocuments.dataDocument.find(
      doc => doc.documentTypeName === "Currículum adicional"
    );

    if (curriculum) {
      window.open(curriculum.documentPath);
    } else {
      console.error("Document not found.");
    }
  };
  
  async function saveCV( Namedata , mail , id , jid , score , questions , stars ) {
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('currentsave'),
        U_emp: sessionStorage.getItem('Grupo'),
        CVData: { name: Namedata, mail: mail, id: id, JID: jid, Nota: score, questions: questions, stars: stars}
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
  };

  async function handleFileChange ( event ) {
    setwait(true);
    
    const data = new FormData();
    data.append('file', event.target.files[0]);
    data.append('apicall', '06');

    try {
      const reply = await AICALL(data);
      const formatedreply = reply.reply
      .replace(/```json\n/, '') 
      .replace(/\n```/, '');
      const parsedreply = JSON.parse(formatedreply);
      const Namedata = parsedreply.personalInfo.firstName + ' ' + parsedreply.personalInfo.lastName
      try {
        await APICALL({
          apicall: 'CV_create',
          code: sessionStorage.getItem('process'),
          U_emp: sessionStorage.getItem('Grupo'),
          Name: Namedata, 
          CVData: parsedreply
        });
      } catch (error) {
        console.error('Error during fetch:', error);
        return { token: null };
      }
      saveCV(Namedata, parsedreply.personalInfo.emails.primaryEmail, 'CVE_'+Namedata, IDlist.at(-1) )
      setCVlist(prevCVlist => {
        const updatedList = [...prevCVlist, { name: Namedata , id: 'CVE_'+Namedata, new: true }];
        return updatedList
      });
    } catch (error) {
      console.error('Error during fetch:', error);
    }
    setwait(false);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const sortCVlist = () => {
    const sortedList = [...CVlist].sort((a, b) => {
      if (sortType === 'score') {
        const notaA = a.Nota ? JSON.parse(a.Nota) : 0;
        const notaB = b.Nota ? JSON.parse(b.Nota) : 0;
        return notaB - notaA; 
      } else if (sortType === 'stars') {
        const starsA = a.stars ? a.stars : 0;
        const starsB = b.stars ? b.stars : 0;
        return starsB - starsA; 
      } else {
        return a.name.localeCompare(b.name); 
      }
    });
    return sortedList;
  };

  const formatJobData = (jobData) => {
    const title = '<h2>Experiencia Laboral:</h2>\n\n';
  
    const sortedJobs = jobData.slice().sort((a, b) => new Date(b.fromDate) - new Date(a.fromDate));
  
    const formattedJobs = sortedJobs.map(job => {
      const achievements = job.achievements.includes('\n?') 
        ? job.achievements.split('\n?').filter(line => line.trim() !== '') 
        : job.achievements.split('\n').filter(line => line.trim() !== '');
  
      const formattedAchievements = achievements.map(achievement => `🞄 ${achievement}`).join('<br>');
  
      return `
        <strong>${job.jobPosition ? job.jobPosition.toUpperCase() : ''}</strong> ${job.companyName} || ${job.companyActivity ? job.companyActivity.description : ''} <br> ${job.fromDate} - ${job.toDate || 'Presente'}
        <br> ${formattedAchievements}
      `.trim();
    }).join('\n\n ________ \n\n');
  
    return `${title}${formattedJobs}`;
  };

  const formatStudyData = ( studyData ) => {

    const title = '<h2> Formación Académica: </h2>\n\n';
  
    const sortedStudies = studyData.higherEducation.slice().sort((a, b) => new Date(b.entryYear) - new Date(a.entryYear));

    const formattedStudies = sortedStudies.map(study => {
      return `
        <strong>${study.otherCareer ? study.otherCareer.toUpperCase() : ''}${study.minor ? ' | ' + study.minor.toUpperCase() : ''}</strong>${study.otherInstitution} \n ${study.statusName || ''} \n ${study.entryYear} ~ ${study.graduationyear || 'Presente'}
      `.trim();
    }).join('\n\n ________ \n\n');
  
    return `${title}${formattedStudies}`;
  };
  
  const formatPersonalData = ( PD ) => {
    const [day, month, year] = PD.personalInfo.birthDate.split('/').map(Number);
    const birthdate = new Date(year, month - 1, day);
    const today = new Date();
  
    let age = today.getFullYear() - birthdate.getFullYear();
    const monthDiff = today.getMonth() - birthdate.getMonth();
  
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthdate.getDate())) {
      age--;
    }
  
    return `
      <strong>${PD.personalInfo.firstName} ${PD.personalInfo.lastName} ${age ? `, ${age} años` : '' }</strong> \n\n ${PD.presentation || 'Sin Descripción'}
      
      Facebook: 
      ${PD.socialNetworks.facebookLink || ' - - -'} 
      Twitter: 
      ${PD.socialNetworks.twitterLink || ' - - -'} 
      LinkedIn: 
      ${PD.socialNetworks.linkedinLink ? `<a href="${PD.socialNetworks.linkedinLink}" target="_blank" rel="noopener noreferrer">${PD.personalInfo.firstName} ${PD.personalInfo.lastName}</a>` : ' - - -'}
      
      🏠 ${PD.personalInfo.communeName} 
            Region ${PD.personalInfo.regionName} ${PD.personalInfo.residenceCountry ? `, ${PD.personalInfo.residenceCountry.description}` : ''}

      ☎️ ${PD.personalInfo.phoneNumbers[0].number || 'N/A'}

      ✉️ ${PD.personalInfo.emails.primaryEmail || 'N/A'}
    `.trim();
  };
  
  const formatQuestions = ( questions ) => {
    let formatted = '';

    if (questions) {
      Object.keys(questions).forEach(key => {
        if (key.includes('question')) {
          const answerKey = key.replace('question', 'answer');
          const cleanQuestion = questions[key].replace(/&#34;/g, '"').trim(); 
          const answer = questions[answerKey] ? questions[answerKey] : '';
          formatted += '<div style="margin-top: 20px;">'; 
          formatted += `<strong style="color: #333;">${cleanQuestion}</strong>`; 
          formatted += `<p style="color: #555; margin: 5px 0;">${answer}</p>`; 
          formatted += '<hr style="border: 1px solid #ccc;"/>'; 
          formatted += '</div>';
          }
      });
      
      return formatted;
    } else {
      return ' <h2> No hay respuestas disponibles </h2>';
    }
  };
  
  const goto01 = () => { navigate('/MisProcesos') };
  const goto08 = () => { navigate('/Formulario') };

  useEffect(() => {
    const fetchData = async () => {
      const data = await APICALL({
        apicall: 'PD_fetch',
        code: sessionStorage.getItem("process"),
        U_emp: sessionStorage.getItem('Grupo')
      });
      localStorage.removeItem('fetched');
      localStorage['fetched'] = data;
      if (data.CVData){
        setCVlist(data.CVData);
        setselectlist(Array.isArray(data.SelectList) 
        ? data.SelectList        
        : data.SelectList             
          ? [data.SelectList]        
          : []);
      }
      if (data.JobId && Array.isArray(data.JobId)){
        setIDlist(data.JobId);
        data.JobId.forEach(id => {
          handlecandidatos(id);
        });
      } else if (data.JobId && !Array.isArray(data.JobId)) {
        setIDlist(data.JobId)
        handlecandidatos(data.JobId);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  //############################################################################################################
  //############################################################################################################

  return (
    <div className="BackGround">
      <div className="typing-effect-container">
        <h2 className="typing-effect">
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        👍 Hey, revisemos los candidatos que he reclutado para tu proceso y mi recomendación! 👍 
        </h2> 
      </div>
      <div className='MainBody'>
        <div className='boxfit' style={{ maxWidth: '20vw' }}>
          <div style={{display:'flex'}}>
            <h3 className='boxtitle'>Candidatos : {CVlist.length}</h3> 
            <button
              className="sortbutton"
              onClick={() => {
                if (sortType === 'score') {
                  setSortType('stars'); 
                } else if (sortType === 'stars') {
                  setSortType('name'); 
                } else {
                  setSortType('score'); 
                }
              }}
              style={{ borderRadius: '1vh 3vh 0vh 0vh' }}>
              {sortType === 'score' && (
                <>
                  % <i className="fa-solid fa-arrow-down-short-wide"></i>
                </>
              )}
              {sortType === 'stars' && (
                <>
                  ☆ <i className="fa-solid fa-arrow-down-short-wide"></i>
                </>
              )}
              {sortType === 'name' && (
                <>
                  A-Z <i class="fa-solid fa-arrow-down-a-z"></i>
                </>
              )}
            </button>
          </div>
          <div className='notas'>
            <button style={{position:'sticky', top: 0, zIndex: 4, marginBottom:'0.5vh'}} 
                    // className = {sessionStorage.getItem('rol') === 'Admin' ? '' : 'hidden' } 
                    onClick={HandleIDFetch}>
              Agregar ID de proceso
            </button>
            <button style={{position:'sticky', top: '4vh', zIndex: 4, marginBottom:'0.5vh'}}
                    onClick={handleButtonClick}> 
              Importar CV 
            </button>
            <input 
              type="file" 
              className='hidden'
              ref={fileInputRef} 
              onChange={handleFileChange} 
            />
            {CVlist && CVlist.length > 0 ? (
              sortCVlist().map((reply, index) => (
                <div key={reply.name} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <button
                    className={ reply.name === selectedCVId  ?  'start-button highlighted' : 
                                                reply.new?      'start-button green' : 
                                                                'start-button'}
                    onClick={() => {
                      lookCV(reply.id);
                      setSelectedCVId(reply.name);
                      setselectQuest(reply.questions);
                      setscore(reply.stars);
                      ;
                    }}
                  >
                    {reply.name}
                  </button>
                  <button className={ !reply.Nota ? 'middle-button' : 
                                                    JSON.parse(reply.Nota) < 50 ? 'middle-button red' : 
                                                                                  JSON.parse(reply.Nota) < 70 ? 'middle-button yellow' : 
                                                                                                                'middle-button blue'}
                          style={{maxWidth:'3vw'}}
                          onClick={() => {
                            setwait(true);
                            handleReneeopinion(CV, selectedCVId).then(nota => {
                              console.log(reply)
                              saveCV(reply.name, reply.mail, reply.id, reply.JID, nota);
                              setwait(false);
                            });
                          }}>
                    {reply.Nota ? JSON.parse(reply.Nota) : ' - '}{'%'}
                  </button>
                  <button className={ !reply.stars ? 'middle-button' : 
                                                    JSON.parse(reply.stars) < 3 ? 'middle-button red' : 
                                                                                  JSON.parse(reply.stars) < 5 ? 'middle-button yellow' : 
                                                                                                                'middle-button blue'}
                          style={{maxWidth:'3vw'}}>
                    {reply.stars ? JSON.parse(reply.stars) : ' - '}{'☆'}
                  </button>
                  <input  type="checkbox" 
                          className='select-checkbox'
                          checked={selectlist ? selectlist.includes(reply.name) : false}
                          onChange={() => {
                            Handleselect(reply.name);
                          }}></input>
                </div>
              ))
            ) : (
              <button className='PublicButoff'>No hay candidatos</button>
            )}
          </div>
        </div>
        &nbsp;
        <div className='boxfit' style={{ maxWidth: '20vw' }}>
          <h3 className='boxtitle'>Perfil personal</h3>
          <div className='notas'>
            <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
              <img
                src={CV && CV.personalInfo.picture ? CV.personalInfo.picture : '/default.png'}
                alt="Profile"
                style={{
                  width: '150px',
                  height: '150px',
                  objectFit: 'cover',
                  borderRadius: '50%',
                  position:'stick'
                }}
              />
              <div className={CV ? '' : 'hidden'}>
                <StarRating initialRating={score} onRatingChange={handleRatingChange} />
              </div>

              <button className={CV ? sessionStorage.getItem('rol') === 'Admin' ? '' : 'hidden' : 'hidden'} style={{marginBottom:'0.5vh'}}
                      onClick={() => {const Name = CV.personalInfo.firstName + ' ' + CV.personalInfo.lastName;
                                      saveCV(Name, CV.personalInfo.emails.primaryEmail);
                                      window.alert('Mail Reimportado:  \n' + CV.personalInfo.emails.primaryEmail)
                                      }}>
                        Reimportar mail</button>

              <details style={{width:'-webkit-fill-available'}}>
                <summary className={CV ? '' : 'hidden'} style={{marginBottom:'0.5vh'}}>Exportar CV</summary>
                <div>
                  <button style={{marginBottom:'0.5vh', width:'80%'}} className={selectedCVId ? '' : 'hidden'} onClick={() => exportToPDFCV( false )}>
                    CV Completo Reneé</button>
                  <button style={{marginBottom:'0.5vh', width:'80%'}} className={selectedCVId ? '' : 'hidden'} onClick={() => exportToPDFCV( true )}>
                    CV Ciego Reneé</button>
                  {CV?.multipleDocuments?.dataDocument?.some(doc => doc.documentTypeName === "Currículum adicional") && (
                  <button style={{marginBottom:'0.5vh', width:'80%'}} onClick={handleCVpropio}>
                    CV propio</button>)}
                </div>
              </details>
              <div
                className="notas-nested"
                dangerouslySetInnerHTML={{ __html: CV ? formatPersonalData(CV) : '' }}
                style={{
                  whiteSpace: 'pre-wrap'
                }}
              />
            </div>
          </div>
        </div>
        &nbsp;
        <div className='boxfit'>
          <div style={{display:'flex'}}>
            <h3 className='boxtitle'>{screentype === 'perfil' ? 'Perfil del candidato' : 'Preguntas Introductorias'}</h3>
            <button className='sortbutton'
                    onClick={() => setscreentype(screentype === 'perfil' ? 'preguntas' : 'perfil')}
                    style = {{ textAlign:'left' , padding:'1vh', borderRadius:'1vh 3vh 0vh 0vh' }}
                    >
              {screentype === 'perfil' ? ' ir a Preguntas Introductorias' : ' ir a Perfil del candidato'}
            </button>
          </div>
          <div
            className= { screentype === 'perfil' ? "notas" : 'hidden'}
            dangerouslySetInnerHTML={{ __html: CV ? formatJobData(CV.workExperience) 
                                                  + '\n\n\n\n'
                                                  + formatStudyData(CV.studies) : '' }}
            style={{
              whiteSpace: 'pre-wrap',
              overflowY: 'auto',
              textAlign: 'left'
            }}
          />
          <div
            className= { screentype !== 'perfil' ? "notas" : 'hidden'}
            dangerouslySetInnerHTML={{ __html: CV ? formatQuestions(selectQuest) : '' }}
            style={{
              whiteSpace: 'pre-wrap',
              overflowY: 'auto',
              textAlign: 'left'
            }}
          />
        </div>
      </div>
      <div className='bottom'>
        <button onClick={goto01}>volver</button>
        <button className = {sessionStorage.getItem('rol') === 'Admin' ? '' : 'hidden' } onClick={() => handleReneeopinion(CV,selectedCVId)}>Consulta Manual a Renee</button>
        <div style={{marginTop:'-1vh', height:'1vh'}}>{wait === false ? (
              <div>
              </div>
            ) 
            : (
              <div className='box'>
                <div className="loader-container">
                  <div  className="pulsing-dot">
                  <div></div>
                  <div></div>
                  <div></div>
                  </div>
                </div>
              </div>
            )}</div>
        <button className='last-button' onClick={goto08}>Guardar y Continuar</button>
      </div>
    </div>
  );
} 
