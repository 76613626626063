
//=============================================================================
// Función Genérica Renee_AI
//=============================================================================

export default async function AICALL(data) {
  const options = {
    method: 'POST',
  };

  if (data instanceof FormData) {
    options.body = data;
  } else {
    options.headers = { 'Content-Type': 'application/json' };
    options.body = JSON.stringify(data);
  }

  try {
    // const response = await fetch('http://localhost:8060/AIcall', options);
    const response = await fetch('https://api.renee.cl/Aicall', options);

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return await response.json();
  } catch (error) {
    console.error('Error during fetch:', error);
    return { response: '' };
  }
}
