
//=============================================================================
// Pantalla Construcción de Formulario
//=============================================================================

import './css/App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import APICALL from './APICALL';
import LOGCALL from './LOGCALL';
import AICALL from './AICALL';

export default function Formulario() {
  const navigate = useNavigate();
  const [CVlist, setCVlist] = useState([]);
  const [selectlist, setselectlist] = useState([]);
  const [selectedName, setSelectedName] = useState('');
  const [CompName, setCompName] = useState('');
  const [selectedMail, setSelectedMail] = useState('');
  const [selectedJID, setSelectedJID] = useState('');
  const sortType = useState('score');
  const [fetchedData, setFetchedData]     = useState(''); 
  const [Form, setForm] = useState([]); 
  const [FormID, setFormID] = useState(''); 
  const [Perfil, setPerfil] = useState(''); 
  const [showPopup, setShowPopup] = useState(false);
  const [showPopup2, setShowPopup2] = useState(false);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [wait, setwait] = useState(false);

  const baseUrl = "https://candidatos.renee.cl";
  // const baseUrl = "http://localhost:3001";


  async function HandleSelect(name) {

    setCVlist(prevCVlist =>
      prevCVlist.map(cv =>
        cv.name === name
          ? { ...cv, select: !cv.select} 
          : cv
      )
    );
    const updatedSelectList = selectlist.includes(name)
      ? selectlist.filter(id => id !== name)
      : [...selectlist, name];
  
    setselectlist(updatedSelectList);
  
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        SelectList: updatedSelectList 
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
  };

  async function SaveForm() {
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        Form: Form,
        FormID : FormID
      });
    } catch (error) {
      console.error('Error during fetch:', error);
    }

    try {
      const data = await APICALL({
        apicall: 'PD_fetch',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo')
      });
      setFormID(data.FormID)
    } catch (error) {
      console.error('Error during fetch:', error);
    }
  };

  async function HandleMailAll() {
    for (const reply of CVlist) {
      if (selectedCandidates.includes(reply.name)) {
        try {
          await HandleMail(reply.mail, reply.name, reply.JID);
        } catch (error) {
          console.error(`Failed to send mail to ${reply.name}:`, error);
        }
      }
    }
    window.alert('Formularios enviados a todos los miembros seleccionados ')
    setShowPopup(false); 
  };
  
  async function HandleMail(mail, name, JID) {
    try {
      await LOGCALL({
        apicall: 'FM_SEND',
        code: sessionStorage.getItem('process'),
        Mail : mail,
        Title : fetchedData.Charge_title,
        JID : JID, 
        Name : name,
        E_name: CompName,
        Text : replaceSpecialChars(fetchedData.GPToferta)
                .replace(/\*\*(.*?)\*\*/g, '<br/><br/><strong>$1</strong><br/><br/>')
                // eslint-disable-next-line
                .replace(/\~(.*?)\~/g, '<li>$1<br/>'),
        FormID : FormID
      });
    } catch (error) {
      console.error('Error during fetch:', error);
    }
  };

  async function AddPreguntaRenee () {
    setwait(true);

    const instruction = 'Genera una pregunta simple sin alternativas relevante para el perfil : ' + Perfil 
    + 'que contemple aspectos técnicos o blandos de la posición'
    
    try {
      const consulta = await AICALL({
        apicall: '04',
        data: instruction
      });
      setForm([...Form, { title: consulta.reply, content: '' , type: 'Texto'}]);
    
    } catch (error) {
      console.error('Error during fetch:', error);
    }
    setwait(false);
    
  };

  const replaceSpecialChars = (text) => {
    const replacements = {
      'á': '&aacute;',
      'é': '&eacute;',
      'í': '&iacute;',
      'ó': '&oacute;',
      'ú': '&uacute;',
      'Á': '&Aacute;',
      'É': '&Eacute;',
      'Í': '&Iacute;',
      'Ó': '&Oacute;',
      'Ú': '&Uacute;',
      'ñ': '&ntilde;',
      'Ñ': '&Ntilde;',
      'ü': '&uuml;',
      'Ü': '&Uuml;',
    };
    return text.replace(/[áéíóúÁÉÍÓÚñÑüÜ]/g, match => replacements[match]);

  };

  const SortCVlist = () => {
    const sortedList = [...CVlist].sort((a, b) => {
      if (sortType === 'stars') {
        const starsA = a.stars ? a.stars : 0;
        const starsB = b.stars ? b.stars : 0;
        return starsB - starsA; 
      } else {
        return a.name.localeCompare(b.name); 
      }
    });
    return sortedList;
  };

  const openConfirmationPopup = () => {
    setSelectedCandidates(CVlist.map(cv => cv.name));
    setShowPopup(true);
  };

  const toggleCandidateSelection = (name) => {
    setSelectedCandidates(prev =>
      prev.includes(name) ? prev.filter(item => item !== name) : [...prev, name]
    );
  };

  const HandleInputChange = (index, field, value) => {
    const updatedForm = [...Form];
    updatedForm[index][field] = value;
    setForm(updatedForm);
  };

  const AddPregunta = () => {
    setForm([...Form, { title: '', content: '' , type: 'Texto'}]);
  };
  
  const HandleCorrect = (questionIndex, altIndex) => {
    const updatedForm = [...Form];
  
    updatedForm[questionIndex].alternatives[altIndex].correct = 
      !updatedForm[questionIndex].alternatives[altIndex].correct;
  
    setForm(updatedForm);
  };

  const DeletePreunta = (indexToRemove) => {
    const updatedForm = Form.filter((_, index) => index !== indexToRemove);
    setForm(updatedForm);
  };

  const MoveUp = (index) => {
    if (index > 0) {
      const newForm = [...Form];
      [newForm[index], newForm[index - 1]] = [newForm[index - 1], newForm[index]];
      setForm(newForm);
    }
  };

  const MoveDown = (index) => {
    if (index < Form.length - 1) {
      const newForm = [...Form];
      [newForm[index], newForm[index + 1]] = [newForm[index + 1], newForm[index]];
      setForm(newForm);
    }
  };

  const HandleType = (index) => {
    setForm((prevQuestions) => {
      return prevQuestions.map((q, i) => {
        if (i === index) {
          let newType;
          switch (q.type) {
            case "Texto":
              newType = "Alt Unica";
              break;
            case "Alt Unica":
              newType = "Alt Multiple";
              break;
            case "Alt Multiple":
              newType = "Texto";
              break;
            default:
              newType = "Texto";
          }
          return { ...q, type: newType };
        }
        return q;
      });
    });
  };
  
  const HandleAlternativeChange = (questionIndex, altIndex, value) => {
    setForm(prevForm => {
      const updatedForm = [...prevForm];
      
      if (updatedForm[questionIndex].alternatives[altIndex]) {
        updatedForm[questionIndex].alternatives[altIndex] = {
          ...updatedForm[questionIndex].alternatives[altIndex],
          label: value
        };
      }
      
      return updatedForm;
    });
  };
  
  const AddAlternative = (questionIndex) => {
    const updatedForm = [...Form];
    updatedForm[questionIndex].alternatives = updatedForm[questionIndex].alternatives || [];
    updatedForm[questionIndex].alternatives.push({ 'label': '', 'selected': false, 'correct': false });
    setForm(updatedForm);
  };

  const RemoveAlternative = (questionIndex, altIndex) => {
    const updatedForm = [...Form];
    updatedForm[questionIndex].alternatives.splice(altIndex, 1);
    setForm(updatedForm);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        alert('URL copiada al portapapeles!');
      })
      .catch(err => {
        console.error('Error al copiar al portapapeles: ', err);
      });
  }

  const goto07 = () => { navigate('/Candidatos') };  
  const goto09 = () => { navigate('/Respuestas') };  

  useEffect(() => {
    const fetchData = async () => {
      const data = await APICALL({
        apicall: 'PD_fetch',
        code: sessionStorage.getItem("process"),
        U_emp: sessionStorage.getItem('Grupo')
      });
      
      setPerfil(data.GPTreply)
      setFormID(data.FormID)
      setCompName(data.E_name)
      setFetchedData(data)
      localStorage.removeItem('fetched');
      localStorage['fetched'] = data;
  
      const PreguntaD1 = { "title": "Número de teléfono actualizado", "content": " ", "type":'Texto'};
      const PreguntaD2 = { "title": "En caso que seas seleccionado, ¿cuál es tu disponibilidad?", "content": " ", "type":'Texto' };
      const PreguntaD3 = { "title": "Coméntanos cuál es tu nivel de renta actual o tu última renta en caso que no estés trabajando", "content": " ", "type":'Texto' };
      const PreguntaD4 = { "title": "Coméntanos cuál es tus expectativas de renta líquida (puede ser un rango)", "content": " ", "type":'Texto' };

      const data2 = await APICALL({
        apicall: 'FM_fetch',
        code: sessionStorage.getItem("process"),
        U_emp: sessionStorage.getItem('Grupo'),
        FormID: data.FormID
      });
      
      if (data2.length > 0) {
        setForm(data2);
      } else {
        setForm([PreguntaD1, PreguntaD2, PreguntaD3, PreguntaD4]);
      }

      if (data.CVData){
        setCVlist(data.CVData);
        setselectlist(Array.isArray(data.SelectList) 
        ? data.SelectList               
        : data.SelectList               
          ? [data.SelectList]         
          : []);
      }
    };
  
    fetchData();
    // eslint-disable-next-line
  }, []);
  
  //############################################################################################################
  //############################################################################################################
  
  return (
    <div className="BackGround">

      <div className={showPopup? 'WarningBG' : 'hidden'}>
        <div className={showPopup? 'warningbox' : 'hidden'}>
          <h2>Confirme a quienes se les enviara el formulario</h2>
          <div style={{alignItems:'flex-start', fontSize:'1.1rem'}} className='notas'>
            {CVlist.map((reply, index) => (
              <div key={reply.name} style={{ display: 'flex', width: '-webkit-fill-available', alignItems: 'center', marginBottom: '10px' }}>
                <button
                  className={selectedCandidates.includes(reply.name) ? 'start-button green' : 
                                                                        'start-button'}
                  onClick={() => {
                    toggleCandidateSelection(reply.name);
                  }}
                >
                  {reply.name}
                </button>
                <input  type="checkbox" 
                        className='select-checkbox'
                        checked={selectedCandidates.includes(reply.name)}
                        onChange={() => {
                          toggleCandidateSelection(reply.name);
                        }}></input>
              </div>
            ))}
          </div>
          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button onClick={HandleMailAll}>Confirmar y Enviar</button>
            <button onClick={() => setShowPopup(false)}> Cancelar</button>
          </div>
        </div>
      </div>  

      <div className={showPopup2 ? 'WarningBG' : 'hidden'}>
        <div className={showPopup2 ? 'warningbox' : 'hidden'}>
          <h2>Confirme envio de formulario:</h2>
          <p style={{ alignItems: 'center', fontSize: '1.2rem' }}>
            se le enviara el formulario a: <strong>{selectedName}</strong> <br /><br />
            con la dirección de correo: <strong>{selectedMail}</strong> <br /><br />
            O alternativamente, puedes copiar la URL para enviarla manualmente: <br /><br />
            <button 
              style={{ cursor: 'pointer' }} 
              onClick={() => copyToClipboard(`${baseUrl}?NM=${selectedName}&PID=${FormID}`)}
            >
              <strong>Has click aqui para copiar la URL del formulario</strong>
            </button>
          </p>
          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button onClick={() => {HandleMail(selectedMail, selectedName, selectedJID); window.alert(` Formulario enviado al candidato ${selectedName} `); setShowPopup2(false) }}>Confirmar y Enviar</button>
            <button onClick={() => setShowPopup2(false)}>Cancelar</button>
          </div>
        </div>
      </div>

      <div className="typing-effect-container">
        <h2 className="typing-effect">
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          📑 Vamos a evaluar más profundamente a tus candidatos, Diseñemos un formulario para evaluarlos 📑
        </h2>
      </div>
      <div className='MainBody'>
        <div className='boxfit' style={{ maxWidth: '20vw' }}>
          <div style={{display:'flex'}}>
            <h3 className='boxtitle'> Candidatos : {selectlist.length}</h3> 
          </div>
          <div  className='notas'
                style={{justifyContent:'flex-start'}}>
            <button style={{position:'sticky', top: 0, zIndex: 2, marginBottom:'0.5vh'}} 
                    onClick={openConfirmationPopup}>
              Enviar Formulario a todos los candidatos
            </button>
          {CVlist && CVlist.length > 0 ? (
            SortCVlist()
              .filter(reply => selectlist.includes(reply.name))
              .map((reply, index) => (
              <div key={reply.name} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <button
                  className={ selectlist.includes(reply.name) ? 'start-button green' :  
                                                                'start-button'}
                >
                  {reply.name}
                </button>
                <button 
                  onClick={() => {
                    setSelectedName(reply.name);
                    setSelectedMail(reply.mail);
                    setSelectedJID(reply.JID);
                    setShowPopup2(true);
                  }}
                  style={{width: '50%'}}
                  className='middle-button'
                >
                  Enviar Formulario
                </button>
                <input  type="checkbox" 
                        className='select-checkbox'
                        checked={selectlist ? selectlist.includes(reply.name) : false}
                        onChange={() => {
                          HandleSelect(reply.name);
                        }}></input>
              </div>
            ))
          ) : (
            <button>No hay candidatos</button>
          )}
          </div>
        </div>
        &nbsp;
        <div className='boxfit'>
          <h3 className='boxtitle'>Formulario Pre-Screening : {FormID}</h3>
          <div className='notas' style={{overflow: 'scroll'}}>
            <div style={{display:'flex', marginRight: '3vh',marginTop:'-8px',paddingTop:'1vh', width:'-webkit-fill-available', position:'fixed', backgroundColor:'var(--light)'}}>
              <button onClick={AddPregunta} style={{marginRight: '1vh'}}>Agrega una Pregunta</button> 
              <button onClick={AddPreguntaRenee} style={{marginRight: '3vh'}}>Agrega una pregunta creada por Renee</button>
            </div>
            <div style={{marginTop: '5vh'}}>
            {Form.map((block, index) => (
              <div key={index} className='notas' style={{ display:'flex', flexDirection:'row', marginBottom: '1vh', marginRight: '1vh', gap: '1vh'}}>
                <div style={{flex: 1}}>
                  <div style={{display:'flex'}}>
                    {index + 1} <textarea
                      value={block.title}
                      className='notas'
                      onChange={(e) => HandleInputChange(index, 'title', e.target.value)}
                      placeholder="Editar título"
                      rows="1"
                      style={{height: '15vh', marginBottom: '1vh' , marginLeft:'1vh'}}
                    />
                  </div>
                  {block.alternatives && block.alternatives.map((alternative, altIndex) => (
                    <div style={{display:'flex', gap:'1vh'}} key={altIndex}>
                      <input
                        type="text"
                        value={alternative.label}
                        className={block.type === 'Texto'? 'hidden' : 'notas'}
                        onChange={(e) => HandleAlternativeChange(index, altIndex, e.target.value)}
                        placeholder={`Alternativa ${altIndex + 1}`}
                        style={{ width: '100%', marginBottom: '1vh' }}
                      />
                      <button onClick={() => HandleCorrect(index,altIndex)}
                              style={{width:'auto',
                                      marginBottom:'0.5vh',
                              }} 
                              className={block.type === 'Texto' ? 'hidden' : alternative.correct ? 'green' : 'red'}> {alternative.correct ? 'Correcta' : 'Incorrecta'}</button>
                      <button onClick={() => RemoveAlternative(index, altIndex)}
                              style={{width:'auto', marginBottom:'0.5vh'}}
                              className={block.type === 'Texto'? 'hidden' : ''}>X</button>
                    </div>
                  ))}
                  <button onClick={() => AddAlternative(index)}
                          className={block.type === 'Texto'? 'hidden' : ''}>Agregar Alternativa</button>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
                  <button onClick={() => MoveUp(index)} style={{marginBottom: '0.5vh'}}>Mover ▲</button>
                  <button onClick={() => MoveDown(index)} style={{marginBottom: '0.5vh'}}>Mover ▼</button>
                  <button onClick={() => HandleType(index)} style={{marginBottom: '0.5vh'}}>{block.type} </button>
                  <button onClick={() => DeletePreunta(index)} style={{marginBottom: '0.5vh'}}>Eliminar Pregunta</button>
                </div>
              </div>
            ))}
            </div>
          </div>
        </div>
      </div>
      <div className='bottom'>
        <button onClick={goto07}>volver</button>
        <button onClick={SaveForm}>Guardar Formulario</button>
        <div style={{marginTop:'-1vh', height:'1vh'}}>{wait === false ? (
              <div>
              </div>
            ) 
            : (
              <div className='box'>
                <div className="loader-container">
                  <div  className="pulsing-dot">
                  <div></div>
                  <div></div>
                  <div></div>
                  </div>
                </div>
              </div>
            )}</div>
        <button className='last-button' onClick={() => {SaveForm() ; goto09()}}>Guardar y Continuar</button>
      </div>
    </div>
  );
}
