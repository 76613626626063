import React from 'react';

const StarRating = ({ initialRating, onRatingChange }) => {
  const totalStars = 5;

  const handleClick = (newRating) => {
    onRatingChange(newRating); 
  };

  return (
    <div style={{ fontSize: '24px', color: 'var(--main)', cursor: 'pointer' }}>
      {Array.from({ length: totalStars }, (_, index) => {
        const starValue = index + 1;
        // console.log('start'+initialRating);
        return (
          <span
            key={index}
            onClick={() => handleClick(starValue)}
            style={{ margin: '0 2px' }} 
          >
            {starValue <= initialRating ? '★' : '☆'}
          </span>
        );
      })}
    </div>
  );
};

export default StarRating;
