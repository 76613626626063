
//=============================================================================
// Pantalla Listado de Procesos
//=============================================================================

import './css/App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import APICALL from './APICALL';

export default function Pprocess() {

  const navigate = useNavigate();
  const [replies, setReplies] = useState([]);
  const [filteredReplies, setFilteredReplies] = useState([]); 
  const [loading, setLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState(''); 
  const [Check, setCheck] = useState(false);
  const [Warning, setWarning] = useState(false);
  const [arch, setarch] = useState(false);
  const [selectedReply, setSelectedReply] = useState('');
  const [filterQuery, setFilterQuery] = useState(''); 
  const [showArchived, setShowArchived] = useState(false);

  let [grupos] = useState([]);

  async function List(state) {
    setLoading(true);
    const process = await APICALL({
      apicall: 'PD_list',
      R_name: sessionStorage.getItem('token'),
      U_emp: sessionStorage.getItem('Grupo')
    });
  
    if (process && Array.isArray(process)) {
      let filteredProcesses = process.filter(item => 
        item[1].toUpperCase() === sessionStorage.getItem('Grupo').toUpperCase() &&
        (state ? item.includes("archived: true") : !item.includes("archived: true"))
      );
  
      for (let i = 0; i < process.length; i++) {
        if (grupos.indexOf(process[i][1]) === -1) {
          grupos.push(process[i][1]);
        }
      }
      sessionStorage.setItem('gruposlista', JSON.stringify(grupos));
  
      const transformedReplies = filteredProcesses.map(item => ({
        reply: item[2].replace(/_/g, ' | '),
        value: item[0]
      }));
      
      setReplies(transformedReplies);
      setFilteredReplies(transformedReplies);
      setLoading(false);
    } else {
      setReplies([]); 
      setLoading(false);
    }
  };
  
  async function FetchData() {
    const data = await APICALL({
      apicall: 'PD_fetch',
      code: sessionStorage.getItem("process"),
      U_emp: sessionStorage.getItem('Grupo')
    });
    sessionStorage.setItem('perfil', data.GPTreply);
    setFetchedData(data);
  };

  async function Handlecheck (value, reply) {
    setSelectedReply(value);
    let transformedback = value.replace(/ \| /g, '_');
    sessionStorage.removeItem("process");
    sessionStorage.removeItem("name");
    sessionStorage.setItem("process", transformedback);
    sessionStorage.setItem("name", reply);
    await setFetchedData(FetchData());
    setCheck(true);
  };

  async function archive (value) {
    setLoading(true);
    await APICALL({
      apicall: 'PD_arch',
      code: sessionStorage.getItem("process"),
      R_name: fetchedData.R_name, 
      U_emp: sessionStorage.getItem('Grupo'),
      archived: value
    });
    List(!value);
    setCheck(false);
    setLoading(false);
    setarch(false);
  };

  async function DropProcess () {
    setLoading(true);
    await APICALL({
      apicall: 'PD_drop',
      code: sessionStorage.getItem("process"),
      R_name: fetchedData.R_name, 
      U_emp: sessionStorage.getItem('Grupo')
    });
    List();
    setCheck(false);
    setLoading(false);
    setWarning(false);
  };

  const handleFilterChange = (e) => {
    const query = e.target.value.toLowerCase();
    setFilterQuery(query);
    
    const filtered = replies.filter(reply => 
      reply.reply.toLowerCase().includes(query)
    );
  
    setFilteredReplies(filtered);
  };

  const toggleArchived = () => {
    setShowArchived(!showArchived);
    List(!showArchived); 
  };

  const Goto02 = () => { navigate('/CProceso') };
  const Goto05 = () => { navigate('/Notas') };
  const Goto07 = () => { navigate('/Candidatos') };

  useEffect(() => {
    sessionStorage.removeItem('process');
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2 * 1000);
    List();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const DataItems = [
    { label: 'EMPRESA', value: fetchedData.E_name ? fetchedData.E_name.toUpperCase() : ' - - - ' }, 
    { label: 'CARGO', value: fetchedData.Charge_name ? fetchedData.Charge_name.toUpperCase() : ' - - - ' }, 
    { label: 'HEAD HUNTER', value: fetchedData.R_name ? fetchedData.R_name.toUpperCase() : ' - - - ' }, 
    { label: 'INTERLOCUTOR', value: fetchedData.I_name ? fetchedData.I_name.toUpperCase() : ' - - - ' },
    { label: 'ID', value: Array.isArray(fetchedData.JobId) ? fetchedData.JobId.join(' | ') : (fetchedData.JobId || ' - - - ') },
    { label: 'FECHA LIMITE', value: fetchedData.Timewith ? fetchedData.Timewith.toUpperCase() + ' DIAS' : ' - - - ' }
  ];

  //############################################################################################################
  //############################################################################################################  

  return (
    <div className="BackGround">	

      <div className={localStorage.getItem("Ver") === 'V0.30'? 'hidden' : 'WarningBG'}>
        <div className={localStorage.getItem("Ver") === 'V0.30'? 'hidden' : 'warningbox'}>
          <h2>¡Bienvenidos a la versión 0.30 de ReneeApp!</h2>
          <p>Estos son los cambios que podrás observar en esta versión:</p>
          <div style={{overflow:'scroll'}} className="popup-content">
            <div style={{textAlign:'start'}}>
              <ul>
                <li>Se modificó el límite de IDs de publicación asignadas a un proceso (2 &rarr; 4).</li>
                <li>Se removió el destacado verde de candidatos seleccionados.</li>
                <li>Se agregó un destacado verde para candidatos nuevos.</li>
                <li>Al cambiar la contraseña, se retorna al usuario a la pantalla de Procesos.</li>
                <li>Se simplificó la lógica de CVs ciegos y se modificó el nombre del PDF generado (name.pdf &rarr; name_ciego.pdf).</li>
                <li>Se modificaron elementos visuales a lo largo del proceso.</li>
                <li>Se ajustaron elementos visuales en el envío de correos a candidatos para hacer el proceso más claro.</li>
                <li>Se corrigieron errores en el proceso de agregado de CV manual.</li>
                <li>Se agregó la opción de cargar PDFs mediante la herramienta de archivos en la pantalla de notas.</li>
                <li>Se modificó el formato del correo enviado para el formulario.</li>
                <li>Se implementó un formato prototipo para exportar respuestas de candidatos en formato Excel.</li>
                <li>Se implementó un sistema de colas para la lectura de datos del proceso.</li>
                <li>Se agregó un botón de acceso rápido a Reneé chat en el Header</li>
              </ul>
            </div>
          </div>
          <div style={{display:'flex', padding:'1vh', gap:'1vh', width:'-webkit-fill-available'}}>
            <button onClick={() => {localStorage['Ver'] = 'V0.30' ; navigate('/MisProcesos')  }}>Cerrar</button>
          </div>
        </div>
      </div>

      <div onClick={() => setWarning(false)} className={Warning ? "WarningBG" : "hidden"}>
        <div className={Warning ? "warningbox" : "hidden"}>
          <h1>¿Está Seguro que desea Eliminar el Proceso?</h1>
          <div className="data-summary">
            {DataItems.map((item, index) => (
              <div key={index} className="data-item">
                <strong>{item.label}:</strong> {item.value}
              </div>
            ))}
          </div>
          Esta acción es PERMANENTE y NO SE PUEDE DESHACER. 
          Al eliminar este proceso, se perderán todos los datos e información asociada, 
          y no habrá forma de recuperarlos.
          Asegúrese de haber realizado copias de cualquier documento o informacion relevante 
          si esta es necesaria, ya que el proceso es IRREVERSIBLE
          <div style={{display:'flex', padding:'1vh', gap:'1vh', width:'-webkit-fill-available'}}>
            <button onClick={DropProcess}>si, quiero Eliminar este proceso</button>
            <button onClick={() => setWarning(false)}>Cancelar</button>
          </div>
        </div>
      </div>

      <div onClick={() => setarch(false)} className={arch && !showArchived ? "WarningBG" : "hidden"}>
        <div className={arch ? "warningbox" : "hidden"}>
          <h1>¿Está Seguro que desea Archivar este Proceso?</h1>
          <div className="data-summary">
            {DataItems.map((item, index) => (
              <div key={index} className="data-item">
                <strong>{item.label}:</strong> {item.value}
              </div>
            ))}
          </div>
          De proceder, este proceso se enviara a la lista de procesos archivados
          <div style={{display:'flex', padding:'1vh', gap:'1vh', width:'-webkit-fill-available'}}>
            <button onClick={() => archive(true)}>si, quiero ARCHIVAR este proceso</button>
            <button onClick={() => setWarning(false)}>Cancelar</button>
          </div>
        </div>
      </div>

      <div onClick={() => setarch(false)} className={arch && showArchived ? "WarningBG" : "hidden"}>
        <div className={arch ? "warningbox" : "hidden"}>
          <h1>¿Está Seguro que desea Desarchivar este Proceso?</h1>
          <div className="data-summary">
            {DataItems.map((item, index) => (
              <div key={index} className="data-item">
                <strong>{item.label}:</strong> {item.value}
              </div>
            ))}
          </div>
          De proceder, este proceso se enviara a la lista de procesos activos
          <div style={{display:'flex', padding:'1vh', gap:'1vh', width:'-webkit-fill-available'}}>
            <button onClick={() => archive(false)}>si, quiero DESARCHIVAR este proceso</button>
            <button onClick={() => setWarning(false)}>Cancelar</button>
          </div>
        </div>
      </div>

      <div className="typing-effect-container">
        <h2 className="typing-effect">
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          Hola, soy Reneé 👋... Indícame si quieres que te ayude en un proceso existente o si quieres crear uno nuevo 
        </h2>
      </div>		

      <div className='MainBody'>
        <div className="ColumnCenter">
          <h3>Tus Procesos</h3>
          <div className='box3'>
            <input 
              type="text" 
              placeholder="Búsqueda de proceso" 
              value={filterQuery}
							className='button'
							style={{marginBottom:'0vh', border:'2px solid var(--main)'}}
              onChange={handleFilterChange} 
            />
            <button onClick={()=>List(showArchived)}>
              Actualizar la lista 
            </button>
            <button onClick={toggleArchived}>
              {!showArchived ? "Mostrar procesos Archivados" : "Mostrar procesos Activos"}
            </button>
          </div>

          <div className='mainselect'>
          <button style={{marginBottom:'0.5vh'}} onClick={Goto02}>Crear nuevo proceso</button>
            {loading ? (
              <div className="loader-container">
                <div className="pulsing-dot">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            ) : (
              <div>
                {filteredReplies.length > 0 ? 
                  filteredReplies
                  .map((item, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                      <button 
                        onClick={() => Handlecheck(item.value, item.reply)} 
                        style={{  marginBottom: '0.5vh', 
                                  marginRight:'0.5vh', 
                                  padding: '1vh' }}
                        className={!showArchived ? (item.value === selectedReply ? 'highlighted' : 'Item' ): 'PublicButoff'}
                      >
                        {item.reply.toUpperCase()}
                      </button>
                      <button 
                        onClick={() => {Handlecheck(item.value, item.reply); setarch(true)}} 
                        className='red'
                        style={{ marginBottom: '0.5vh', aspectRatio: 1, width: '5%'}}
                      >
                        <i className="fas fa-archive"></i>
                      </button>
                    </div>
                  )) : 
                  <button className='PublicButoff'>
                    Sin procesos Activos
                  </button>
                }
              </div>
            )}
          </div>
        </div>

        <div className="ColumnCenter">
          <h3>Datos de tu proceso</h3>
          {Check ? (
          <div className="box3">
            <button onClick={Goto05}>Continuar Proceso</button>
            <button className={fetchedData.JobId? '' : 'hidden'} onClick={Goto07}>Continuar a Candidatos</button>
            <button onClick={() => setWarning(true)}>Eliminar Proceso</button>
          </div>
          ) : (		
          <div className="box3">
            &nbsp;
            <button style={{color:'#60679600',backgroundColor:'#60679600'}}>Continuar Proceso</button>
            <button style={{color:'#60679600',backgroundColor:'#60679600'}}>Continuar a Candidatos</button>
            <button style={{color:'#60679600',backgroundColor:'#60679600'}}>Eliminar Proceso</button>
          </div>
          )}
          <div className='mainselect'>
            <div className={Check ? "data-boxes2" : ' hidden' }>
              {DataItems.map((item, index) => (
                <div key={index} className="data-box">
                  <strong>{item.label}:</strong> {item.value}
                </div>
              ))}
            </div>
            <div className={loading ? "loading" : "hidden"}>
            </div>
          </div> 
        </div>
      </div>
    </div>
  );
}
