
//=============================================================================
// Pantalla Recuperación de Respuestas Pre-Screening
//=============================================================================

import './css/App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import APICALL from './APICALL';
import StarRating from '../components/star';
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export default function Respuestas() {
  const navigate = useNavigate();
  const [CVlist, setCVlist] = useState([]);
  const [selectlist, setselectlist] = useState([]);
  const [selectedCVId, setSelectedCVId] = useState(null);
  const [sortType, setSortType] = useState('score');
  const [Form, setForm] = useState([]); 
  const [FormID, setFormID] = useState(''); 
  const [score, setscore] = useState('0');

  async function handleformcheck(name) {
  
    try {
      const resp = await APICALL({
        apicall: 'FM_fetch',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        FormID: FormID+'_'+name
      });
      setForm(resp);
      setSelectedCVId(name);
      return resp;

    } catch (error) {
      console.error('Error during fetch:', error);
      return [];
    }

  };

  async function handleRatingChange (newRating) {
    setscore(newRating);
    setCVlist(prevCVlist =>
      prevCVlist.map(cv =>
        cv.name === selectedCVId
          ? { ...cv, stars2: newRating} 
          : cv
      )
    );
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        CVData: { name: selectedCVId, stars2: newRating}
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }

  };

  async function Handleselect(name) {
    setCVlist(prevCVlist =>
      prevCVlist.map(cv =>
        cv.name === name
          ? { ...cv, select: !cv.select} 
          : cv
      )
    );
  
    const updatedSelectList = selectlist.includes(name)
      ? selectlist.filter(id => id !== name)
      : [...selectlist, name];
  
    setselectlist(updatedSelectList);
  
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        SelectList: updatedSelectList 
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
  };

  async function CandidatesData(CVlist, handleformcheck) {
    const headersSet = new Set(); 
    const candidateData = []; 
  
    for (const candidate of CVlist) {
      const formData = await handleformcheck(candidate.name);
  
      if (formData.error) {
        console.warn(`Error fetching form for candidate ${candidate.name}: ${formData.error}`);
        continue; 
      }
  
      const formattedData = {};
      formattedData["Candidate"] = candidate.name;
  
      formData.forEach(item => {
        headersSet.add(item.title || "Untitled");
  
        if ((item.type === "Alt Multiple" || item.type === "Alt Unica") && item.alternatives) {
          const selectedAlternatives = item.alternatives
            .filter(alt => alt.selected)
            .map(alt => alt.label)
            .join(", ");
          formattedData[item.title || "Untitled"] = selectedAlternatives || " - - - ";
        } else {
          formattedData[item.title || "Untitled"] = item.content || " - - - ";
        }
      });
  
      candidateData.push(formattedData);
    }
  
    const headers = ["Candidate", ...Array.from(headersSet)];
  
    const formattedDataForExcel = [headers];
    candidateData.forEach(dataRow => {
      const row = headers.map(header => dataRow[header] || ""); 
      formattedDataForExcel.push(row);
    });
  
    return formattedDataForExcel;
  }
  
  
  async function exportToExcel(CVlist) {
    const compiledData = await CandidatesData(CVlist, handleformcheck);
  
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("All Candidates");
  
    const headers = compiledData[0];
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell(cell => {
      cell.font = { name: "Arial", size: 12, bold: true, color: { argb: "FFFFFFFF" } };
      cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FF4F81BD" } };
      cell.alignment = { horizontal: "center", vertical: "middle" };
    });
  
    for (let i = 1; i < compiledData.length; i++) {
      const row = worksheet.addRow(compiledData[i]);
      row.eachCell(cell => {
        cell.alignment = { vertical: "top" };
      });
    }
  
    headers.forEach((header, index) => {
      worksheet.getColumn(index + 1).width = 30;
    });
  
    const buffer = await workbook.xlsx.writeBuffer();
    const fileName = `Respuestas_${sessionStorage.getItem("name")}.xlsx`;
    saveAs(new Blob([buffer]), fileName);
  };

  const sortCVlist = () => {
    const sortedList = [...CVlist].sort((a, b) => {
      if (sortType === 'stars') {
        const starsA = a.stars2 ? a.stars2 : 0;
        const starsB = b.stars2 ? b.stars2 : 0;
        return starsB - starsA; 
      } else {
        return a.name.localeCompare(b.name); 
      }
    });
    return sortedList;
  };

  const handleInputChange = (index, field, value) => {
    const updatedForm = [...Form];
    updatedForm[index][field] = value;
    setForm(updatedForm);
  };

  const handleCheckboxChange = (blockIndex, optionIndex, isChecked) => {
    setForm(prevForm => {
      const updatedForm = [...prevForm];
      updatedForm[blockIndex].alternatives[optionIndex].selected = isChecked;
      return updatedForm;
    });
  };

  const handleRadioChange = (blockIndex, selectedOptionIndex) => {
    setForm(prevForm => {
      const updatedForm = [...prevForm];
      updatedForm[blockIndex].alternatives = updatedForm[blockIndex].alternatives.map((alt, optIndex) => ({
        ...alt,
        selected: optIndex === selectedOptionIndex
      }));
      return updatedForm;
    });
  };
  
  const goto08 = () => { navigate('/Formulario') };  

  useEffect(() => {
    const fetchData = async () => {
      const data = await APICALL({
        apicall: 'PD_fetch',
        code: sessionStorage.getItem("process"),
        U_emp: sessionStorage.getItem('Grupo')
      });
      
      setFormID(data.FormID)
      localStorage.removeItem('fetched');
      localStorage['fetched'] = data;
  
      const data2 = await APICALL({
        apicall: 'FM_fetch',
        code: sessionStorage.getItem("process"),
        U_emp: sessionStorage.getItem('Grupo'),
        FormID: data.FormID
      });
      setForm(data2);

      if (data.CVData){
        setCVlist(data.CVData);
        setselectlist(Array.isArray(data.SelectList) 
        ? data.SelectList               
        : data.SelectList               
          ? [data.SelectList]         
          : []);
      }
    };
  
    fetchData();
    // eslint-disable-next-line
  }, []);

  //############################################################################################################
  //############################################################################################################

  return (
    <div className="BackGround">
      <div className="typing-effect-container">
        <h2 className="typing-effect">
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          📑 Ahora revisemos las respuestas de tus candidatos! 📑
        </h2>
      </div>
      <div className='MainBody'>
        <div className='boxfit' style={{ maxWidth: '20vw' }}>
          <div style={{display:'flex'}}>
            <h3 className='boxtitle'>Lista de Candidatos</h3> 
            <button className='sortbutton'
                    onClick={() => setSortType(sortType === 'stars' ? 'name' : 'stars')}>
              {sortType === 'stars' ? 'Estrellas (desc)' : 'Nombre (A-Z)'}
            </button>
          </div>
          <div  className='notas'
                style={{justifyContent:'flex-start'}}>
          <button style={{position:'sticky', top: 0, zIndex: 2, marginBottom:'0.5vh'}} 
                  onClick={() => exportToExcel(CVlist)}>
            Exportar respuestas como XLSX
          </button>
          {CVlist && CVlist.length > 0 ? (
            sortCVlist()
              .filter(reply => selectlist.includes(reply.name))
              .map((reply, index) => (
              <div key={reply.name} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <button
                  className={ reply.name === selectedCVId ? 'start-button highlighted' : 
                                                            selectlist.includes(reply.name) ? 'start-button green' : 
                                                                                              'start-button'}
                  onClick={() => {
                    setscore(reply.stars2);
                    handleformcheck(reply.name);
                  }}
                >
                  {reply.name}
                </button>
                <button className={ !reply.stars2 ? 'middle-button' : 
                                                  JSON.parse(reply.stars2) < 3 ? 'middle-button red' : 
                                                                                JSON.parse(reply.stars2) < 5 ? 'middle-button yellow' : 
                                                                                                              'middle-button blue'}
                        style={{maxWidth:'4vw'}}>
                  {reply.stars2 ? JSON.parse(reply.stars2) : ' - '}{'☆'}
                </button>
                <input  type="checkbox" 
                        className='select-checkbox'
                        checked={selectlist ? selectlist.includes(reply.name) : false}
                        onChange={() => {
                          Handleselect(reply.name);
                        }}></input>
              </div>
            ))
          ) : (
            <button>No hay candidatos</button>
          )}
          </div>
        </div>
        &nbsp;
        <div className='boxfit'>
          <div style={{display:'flex'}}>
            <h3 style={{display:'flex', alignItems: 'center'}} className='boxtitle'>Respuestas Pre-Screening : {selectedCVId} | 
              <div  className={selectedCVId? '' : 'hidden'}
                    style={{marginTop:'0'}}>
                <StarRating initialRating={score} onRatingChange={handleRatingChange} />
              </div>
            </h3>         
          </div>
          <div className='notas' style={{overflow: 'scroll'}}>
          <div>
            {Form.length > 0 ? (
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                {Form.map((block, index) => (
                  <div
                    key={index}
                    className='notas'
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginBottom: '1vh',
                      marginRight: '1vh',
                      gap: '1vh'
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span
                          className='notas-title'
                          style={{ marginLeft: '1vh', fontWeight: 'bold' }}
                        >
                          {block.title}
                        </span>
                      </div>

                      {block.type === 'Texto' ? (
                        <textarea
                          value={block.content}
                          className='notas'
                          readOnly
                          onChange={(e) => handleInputChange(index, 'content', e.target.value)}
                          placeholder="Editar contenido"
                          rows="5"
                          style={{ height: '10vh', marginBottom: '1vh' }}
                        />
                      ) : block.type === 'Alt Unica' ? (
                        <div className="alternatives-container">
                          {block.alternatives && block.alternatives.map((alternative, optIndex) => (
                            <label  key={optIndex} 
                                    style={{ backgroundColor: alternative.correct ? 'var(--light-green)' : 'transparent' }}
                                    className="alternative-label">
                              <input
                                type="radio"
                                className="alternative-checkbox"
                                disabled 
                                checked={alternative.selected || false}
                                onChange={(e) => handleRadioChange(index, optIndex, e.target.checked)}
                              />
                              {alternative.label}
                            </label>
                          ))}
                        </div>
                      ) : block.type === 'Alt Multiple' ? (
                        <div className="alternatives-container">
                          {block.alternatives && block.alternatives.map((alternative, optIndex) => (
                            <label  key={optIndex} 
                                    style={{ backgroundColor: alternative.correct ? 'var(--light-green)' : 'transparent' }}
                                    className="alternative-label">
                              <input
                                type="checkbox"
                                className="alternative-checkbox"
                                disabled 
                                checked={alternative.selected || false}
                                onChange={(e) => handleCheckboxChange(index, optIndex, e.target.checked)}
                              />
                              {alternative.label}
                            </label>
                          ))}
                        </div>
                      ) : null}
                      
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div> 
              <h2>Sin respuesta disponible</h2>
              </div>
            )}
            </div>
          </div>
        </div>
      </div>
      <div className='bottom'>
        <button onClick={goto08}>volver</button>
      </div>
    </div>
  );
}
